<style scoped>
.vue-box {
  background-color: #eee;
  font-size: 14px;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}
.content-box {
  margin: 1em;
  padding: 1em;
  background-color: #fff;
  color: #666;
}
.el-tag {
  border-radius: 1px;
}
/*800之下*/
@media (max-width: 800px) {
  .kapian {
    width: 100% !important;
    margin-right: 0px !important;
  }
  .saoma {
    display: none;
  }
}
/* markdown格式 */
.md-contetn {
  padding-left: 1.5em;
  line-height: 26px;
}
</style>
<style>
/* 多个ul时，切换那个啥 */
.md-contetn h4 {
  margin-left: -0.5em;
}
.md-contetn ul,
.md-contetn ol {
  padding-left: 1em;
}
.md-contetn pre {
  padding: 5px;
  background-color: #eee;
  font-family: "times new roman";
}
</style>

<template>
  <div class="vue-box">
    <!-- 参数栏 -->
    <!-- 功能介绍 -->
    <div class="content-box">
      <div class="kapian" style="width: 100%">
        <el-divider>
          <big><big>获取openid步骤</big></big>
        </el-divider>
        <div class="md-contetn" v-html="md_sy"></div>
        <img :src="
            this.sa.cfg.api_url +
            '/v1/index/enQrcode?url=' +
            encodeURIComponent(
              this.sa.cfg.api_url +
                '/v1/wechat/open/openid?id=' +
                this.m.providerId +
                '&mode=' +
                this.m.mode
            )
          " style="width: 15%; margin-left: 40%" />
      </div>
      <div style="clear: both"></div>
    </div>

    <div class="c-panel">
      <div class="c-title">微信分账用户添加</div>
      <el-form size="mini" v-if="m">
        <el-form-item label="分账用户姓名:">
          <el-input v-model="m.shareName"></el-input>
        </el-form-item>
        <el-form-item label="分账openid:">
          <el-input v-model="m.shareOpenId"></el-input>
        </el-form-item>

        <el-form-item label="商户模式:">
          <el-select v-model="m.mode" @click.native="m.providerId = ''">
            <el-option label="单商户模式" :value="0"></el-option>
            <el-option label="电商收付通模式" :value="1"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="m.mode == 1" label="服务商选择:" placeholder="请选择">
          <el-select v-model="m.providerId" @click.native="getServerProvider()">
            <el-option v-for="item in providerlists" :key="item.providerName" :label="item.providerName" :value="item.providerId" />
          </el-select>
        </el-form-item>

        <el-form-item v-if="m.mode == 0" label="微信公众号:" placeholder="请选择">
          <el-select v-model="m.providerId" @click.native="getWechatProvider()">
            <el-option v-for="item in officaillists" :key="item.providerName" :label="item.officailName" :value="item.officailAppid" />
          </el-select>
        </el-form-item>

        <el-form-item v-if="m.mode == 0" label="微信商户号:">
          <el-input v-model="m.wechatMch"></el-input>
        </el-form-item>

        <!-- <el-form-item label="是否启用">
          <el-radio-group v-model="m.status" size="mini">
            <el-radio :label="1">启用</el-radio>
            <el-radio :label="0">关闭</el-radio>
          </el-radio-group>
        </el-form-item> -->

        <el-form-item>
          <span class="c-label">&emsp;</span>
          <el-button v-if="this.sa_admin.role == 3" type="primary" icon="el-icon-plus" size="mini" @click="ok">确定</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import marked from "marked";
export default {
  props: ["params"],
  data() {
    // 使用步骤
    let md_sy = `
- 注意1:使用分账添加用户服务商必须与微信公众号关联，同时商户不能被风控、冻结、延迟提现、否则将分账失败;
- 注意2:使用微信扫描以下二维码，将获取到的openid复制粘贴到分账openid输入框;
- 注意3:请选择完微信公众号或服务商之后在扫码,否则将无法获取到openId;
			`;
    return {
      m: {
        shareName: "",
        shareOpenId: "",
        status: 1,
        codeMchId: "",
        codeMchName: "",
        providerMch: "",
        wechatMch: "",
        mode: 0,
      },
      p: {
        limit: 20,
        page: 1,
        type: 1,
      },
      providerlists: [],
      officaillists: [],
      md_sy: marked(md_sy),
    };
  },
  methods: {
    ok: function () {
      if (this.submit_check() != "ok") {
        return;
      }
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      };
      let self = this;
      this.sa.ajax(
        "/v1/profitshare_account/add",
        this.m,
        function () {
          self.sa.alert("增加成功", function () { });
        }.bind(this),
        defaultCfg
      );
    },
    submit_check: function () {
      var m = this.m;
      var sa = this.sa;
      if (sa.isNull(m.shareName)) {
        return sa.error("请输入分账用户姓名");
      }
      if (sa.isNull(m.shareOpenId)) {
        return sa.error("请输入分账openid");
      }
      if (m.mode == 0) {
        if (sa.isNull(m.wechatMch)) {
          return sa.error("请输入微信商户号");
        }
      }
      return "ok";
    },
    getServerProvider: function () {
      var defaultCfg = {
        type: "get", //是否是请求体请求
        sleep: 50,
        msg: null,
      };
      this.sa.ajax(
        this.sa.cfg.provider_api_url + "/provider/list",
        function (res) {
          this.providerlists = res.data.records; // 数据
        }.bind(this),
        defaultCfg
      );
    },
    getWechatProvider: function () {
      var defaultCfg = {
        sleep: 50,
        isBody: true, //是否是请求体请求
        msg: null,
      };
      this.p.mchId = this.sa_admin.mchId;
      this.sa.ajax(
        "/v1/officail/list",
        this.p,
        function (res) {
          this.officaillists = res.data.records; // 数据
        }.bind(this),
        defaultCfg
      );
    },
  },
  created() {
    this.sa_admin.$notify.success({
      title: '消息提醒',
      message: '扫码前，请先进行微信公众号选择或服务商选择'
    });
  },
};
</script>


